var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mb-4 pb-0 white rounded-lg elevation-0"
  }, [_c('v-row', {
    staticClass: "px-4 pt-7"
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.Name).label,
      "clearable": "",
      "outlined": "",
      "dense": "",
      "full-width": "",
      "prepend-inner-icon": _vm.icons.mdiMagnify
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.Name).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.Name), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.Name).value"
    }
  })], 1), _c('v-col', [_c('CommonAutocomplete', {
    attrs: {
      "items": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.Roles).items.value,
      "item-text": "displayName",
      "item-value": "name",
      "clearable": "",
      "chips": "",
      "deletable-chips": "",
      "small-chips": "",
      "outlined": "",
      "dense": "",
      "multiple": "",
      "loading": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.Roles).isLoading.value,
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.Roles).label
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.Roles).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.Roles), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.Roles).value"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }